import { useTheme } from '@mui/lab/node_modules/@mui/system';
import { Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Head from 'next/head';
import Image from 'next/image';
import { YouTubeLite } from 'react-youtube-lite';
import Layout from '../components/Layout';
import * as ga from '../lib/ga';

export default function Album() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Layout>
      <Head>
        <title>Tradingpnl</title>
      </Head>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.paper',
          flexGrow: 1,
          pb: 6,
          mt: 6
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            py: 6
          }}
        >
          <Container maxWidth="lg">
            <Grid
              container
              alignItems="center"
              spacing={2}
              flexWrap="nowrap"
            >
              <Grid
                item
                md={7}
                xs={12}
              >
                <Typography
                  component="h1"
                  variant="h2"
                  color="text.primary"
                  textAlign={matches ? 'left' : 'center'}
                  gutterBottom
                >
                  Analyse Trading P&L
                </Typography>
                <Typography variant="h5" color="text.secondary" textAlign={matches ? 'left' : 'center'} paragraph>
                  Automatic tradebook analysis with charts and timelines.
                </Typography>
                <Typography variant='caption' align={matches ? 'left' : 'center'}>We support</Typography>
                <Stack direction="row" spacing={2} justifyContent={matches ? 'left' : 'center'} alignItems="center">
                  <Box display="flex" alignItems="center"><Image src="/upstox.png" alt="upstox" priority width={20} height={20} /> <Typography component="span">Upstox</Typography></Box>
                  <Box display="flex" alignItems="center"><Image src="/angel.webp" alt="angel" priority width={20} height={20} /> <Typography component="span">Angel</Typography></Box>
                  <Box display="flex" alignItems="center"><Image src="/zerodha.jpeg" alt="zerodha" priority width={20} height={20} /> <Typography component="span">Zerodha</Typography></Box>
                  <Box display="flex" alignItems="center"><Image src="/finvasia.png" alt="finvasia" priority width={20} height={20} /> <Typography component="span">Finvasia</Typography></Box>
                  <Box display="flex" alignItems="center"><Image src="/fyerslogo.png" alt="fyers" priority width={20} height={20} /> <Typography component="span">Fyers</Typography></Box>
                </Stack>
              </Grid>
              <Grid
                item
                md={5}
                sx={{
                  display: {
                    md: 'block',
                    xs: 'none'
                  }
                }}
              >
                <Box
                  sx={{
                    height: 420,
                    maxWidth: 419,
                    position: 'relative',
                    '& img': {
                      height: 'auto',
                      position: 'absolute',
                      top: 0,
                      width: '100%'
                    }
                  }}
                >
                  <Image src="/hero.svg" priority alt="Hero" width="100%" height="100%" layout="responsive" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button variant="contained" onClick={() => {
              ga.event({
                action: "home anayse now clicked",
                params: {}
              })
            }} LinkComponent={Link} href="/dashboard">Analyse Now</Button>
            <Button variant="outlined" onClick={() => {
              ga.event({
                action: "how it works clicked",
                params: {}
              })
            }} LinkComponent={Link} href="#how-it-works">How it works?</Button>
          </Stack>
        </Container>

      </Box>
      <Container sx={{ mt: matches ? 40 : 5, mb: 20 }} maxWidth="lg" id="how-it-works">
        <Box mb={3} sx={{ mt: matches ? 10 : 0 }}>
          <Typography variant='h4' textAlign={'center'}> How it works?</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card sx={{ mt: 5, display: 'flex', height: '315px', alignItems: 'center' }}>
              <CardContent>
                <Box>
                  <Stepper activeStep={0} orientation="vertical">
                    <Step>
                      <StepLabel >
                        Download the excel report from your broker
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel >
                        Upload it in the tool
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel >
                        See Beautiful graphs representing your trading performance
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
              <YouTubeLite
                  url="https://www.youtube.com/embed/BAX9p3pOxEQ?rel=0"
                  title='How it works'
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={3} sx={{ mt: matches ? 10 : 0 }}>
              <Typography variant='h4'>Upload your pnl excel file and see all these visualizations and much more...</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Image src="/demo/calendar.png" priority alt="pnl on calendar" width="100%" height="60%" layout="responsive" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Image src="/demo/date-range.png" priority alt="pnl by date range" width="100%" height="60%" layout="responsive" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Image src="/demo/trend.png" priority alt="pnl trend" width="100%" height="60%" layout="responsive" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Image src="/demo/stock.png" priority alt="pnl stock wise" width="100%" height="60%" layout="responsive" />
          </Grid>
        </Grid>
        <Stack
          sx={{ mt: 12 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button variant="contained" LinkComponent={Link} onClick={() => {
            ga.event({
              action: "analyze now above pricing clicked",
              params: {}
            })
          }} href="/dashboard">Analyse Now</Button>
        </Stack>
      </Container>
      <Typography
        align="center"
        color="textSecondary"
        component="p"
        variant="caption"
      >
        The opensource version of this tool is available at <Link onClick={() => {
          ga.event({
            action: "github link clicked",
            params: {}
          })
        }} target="_blank" href='https://github.com/pkvpraveen/tradingpnl-oss/'>github</Link> hosted <Link onClick={() => {
          ga.event({
            action: "oss link clicked",
            params: {}
          })
        }} target="_blank" href='https://oss.tradingpnl.in'>here</Link>
      </Typography>
    </Layout >
  );
}