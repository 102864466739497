import * as React from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import Link from 'next/link';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <MuiLink color="inherit" href="https://tradingpnl.in/">
        Tradingpnl
      </MuiLink>{' '}
      {new Date().getFullYear()}. <br/>
      <MuiLink color="inherit" href="/privacy-policy" target="_blank">Privacy Policy</MuiLink> 
      <MuiLink target="_blank" color="inherit" href="/terms-of-service"> Terms of Service</MuiLink>
    </Typography>
  );
}
