import { AutoGraph } from "@mui/icons-material";
import { AppBar, Box, ButtonBase, Container, Toolbar, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import Copyright from "./Copyright";
import Discord from "./Discord";
import ThemeChange from "./ThemeChange";

export default function Layout ({children}){

  return <>
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
         <AppBar position="fixed">
          <Toolbar>
          <ButtonBase LinkComponent={Link} href="/" ><AutoGraph sx={{ mr: 2 }} />
            <Typography variant="h6" color="inherit"  noWrap>
              Tradingpnl
            </Typography></ButtonBase>
            <Box sx={{flexGrow: 1}}/>
            <Discord/>
            <ThemeChange/>
          </Toolbar>
        </AppBar>
    {children}
     {/* Footer */}
     <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </Box>
        {/* End footer */}
      </Box>
    </>;
}
